export const addToCart = (goodName, price) => {
  if (typeof window === `undefined`) return;

  window.gtag("event", "add_to_cart", {
    value: price,
    items: goodName,
    currency: "RUB",
  });
};

export const goToPay = (goodName, price) => {
  if (typeof window === `undefined`) return;

  window.gtag("event", "begin_checkout", {
    value: price,
    items: goodName,
    currency: "RUB",
  });
};
