import * as React from "react";
import Api from "src/api";
import Button from "src/components/Button";
import Typography from "src/components/Typography";
import { Good } from "src/types";
import { goToPay } from "src/utils/gtm-actions";
// tslint:disable: no-relative-imports
import parentStyles from "../Pay.module.css";
import Coupon from "./Coupon";
import Email from "./Email";
import styles from "./Form.module.css";

type Props = {
  email: string;
  good: Good;
  scenarioId?: string;
};

type State = {
  couponSale: number;
  couponId: string;
  email: string;
};

class Form extends React.Component<Props, State> {
  public state = {
    couponSale: 0,
    couponId: "",
    email: this.props.email,
  };

  public render() {
    return (
      <div>
        <Coupon onChange={this.getCouponAmount} goodId={this.props.good._id} />
        <Email email={this.props.email} handleChange={this.getEmail} />
        <div className={styles.priceButtonContainer}>
          <div className={parentStyles.leftColumn} />
          <div className={styles.totalPrice}>
            <Typography type="h3">{this.getTotalPrice()} Р</Typography>
          </div>
          <div className={styles.buttonWrapper}>
            <Button handleClick={this.initOrder}>ОПЛАТИТЬ</Button>
          </div>
        </div>
      </div>
    );
  }

  private initOrder = async () => {
    const res = await Api.initOrder({
      goodId: this.props.good._id,
      couponId: this.state.couponId,
      email: this.state.email,
      scenarioId: this.props.scenarioId,
    });

    try {
      goToPay(this.props.good.name, this.getTotalPrice());
    } catch (error) {}

    window.location.replace(res.data.url);
  };

  private getCouponAmount = (couponSale: number, couponId: string) => {
    this.setState({ couponSale: couponSale, couponId });
  };

  private getEmail = (email: string) => {
    this.setState({ email });
  };

  private getTotalPrice() {
    const sale = this.state.couponSale;
    const price = this.props.good.price;

    if (!sale) return Math.round(price / 100);
    const boo = sale / 100;
    const discount = price * boo;

    return Math.round(price - discount) / 100;
  }
}

export default Form;
