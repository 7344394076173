import React from "react";
import Typography from "src/components/Typography";
// tslint:disable-next-line: no-relative-imports
import parentStyles from "../Pay.module.css";
import styles from "./PayHeader.module.css";

const Header = (props: { name: string; price: number }) => {
  return (
    <div className={styles.wrapper}>
      <div className={`${styles.nameWrapper} ${parentStyles.leftColumn}`}>
        <Typography type="h5">{props.name}</Typography>
        <div className={styles.line} />
      </div>
      <div className={styles.startPrice}>
        <Typography type="h5">{`${props.price / 100} Р`}</Typography>
      </div>
    </div>
  );
};

export default Header;
