import * as React from "react";
import Input from "src/components/Input";
// tslint:disable: no-relative-imports
import parentStyles from "../../Pay.module.css";
import styles from "./Email.module.css";

type Props = {
  email: string;
  handleChange(e: string): void;
};

type State = {
  error: boolean;
  value: string;
};

class Email extends React.Component<Props, State> {
  public state = {
    error: false,
    value: this.props.email,
  };

  public render() {
    return (
      <div className={styles.wrapper}>
        <Input
          handleChange={this.handleChange}
          handleBlur={this.handleBlur}
          value={this.state.value}
          placeholder="Email"
          wrapperClassName={parentStyles.leftColumn}
          error={this.state.error}
        />
      </div>
    );
  }

  private handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (ev.currentTarget?.value?.length > 40) return ev.currentTarget?.value;
    this.setState({ value: ev.currentTarget.value });
    return ev.currentTarget?.value;
  };

  private handleBlur = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const value = ev.currentTarget?.value;
    const isValid =
      value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value);
    if (isValid) {
      this.setState({ value, error: false });
      this.props.handleChange(value);
      return value;
    }
    this.setState({ error: true, value: "" });
    this.props.handleChange(null);
    return null;
  };
}

export default Email;
