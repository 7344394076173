import * as React from "react";
import Typography from "src/components/Typography";
import icon from "src/images/film-cam-icon.svg";
import { Good } from "src/types";
import Form from "./Form/Form";
import styles from "./Pay.module.css";
import headerStyles from "./PayHeader/PayHeader.module.css";

interface Props {
  good: Good;
  email: string;
  scenarioId: string;
}

const PayMobile: React.SFC<Props> = (props) => {
  return (
    <div className={styles.mobileWrapper}>
      <div className={styles.mobileHeader}>
        <div className={styles.imgWrapper}>
          <img src={icon} alt="иконка камеры" />
        </div>
        <Typography type="h5">{props.good.name}</Typography>
        <div className={headerStyles.startPrice}>
          <Typography type="h5">{`${props.good.price / 100} Р`}</Typography>
        </div>
      </div>
      <Form
        good={props.good}
        email={props.email || ""}
        scenarioId={props.scenarioId}
      />
    </div>
  );
};

export default PayMobile;
