import { navigate } from "gatsby";
import * as React from "react";
import { connect } from "react-redux";
import { Default } from "src/components/querysComponents";
import icon from "src/images/film-cam-icon.svg";
import payIcon from "src/images/payIcon.svg";
import FullWithLayout from "src/routes/FullWithLayout";
import { Good, PageProps, User } from "src/types";
import { addToCart } from "src/utils/gtm-actions";
import Form from "./Form";
import styles from "./Pay.module.css";
import Header from "./PayHeader";
import MobilePay from "./PayMobile";

interface Props extends PageProps, ConnectStateProps {
  location: PageProps["location"] & {
    state: { good?: Good; scenarioId?: string };
  };
}

class Pay extends React.Component<Props> {
  public async componentDidMount() {
    if (!this.props?.location?.state?.good) {
      await navigate("/");
    }

    try {
      const { good } = this.props?.location?.state;
      addToCart(good.name, good.price);
    } catch (error) {}
  }

  public render() {
    const good: Good = this.props?.location?.state?.good;
    const scenarioId: string = this.props?.location?.state?.scenarioId;

    if (!good) return null;
    return (
      <Default
        mobile={
          <MobilePay
            good={good}
            email={this.props?.user?.email || ""}
            scenarioId={scenarioId}
          />
        }
      >
        <FullWithLayout sideBar={<img src={payIcon} alt="potential" />}>
          <div className={styles.payContainer}>
            <div className={styles.imgWrapper}>
              <img src={icon} alt="иконка камеры" />
            </div>
            <div className={styles.contentWrapper}>
              <Header name={good.name} price={good.price} />
              <Form
                good={good}
                email={this.props?.user?.email || ""}
                scenarioId={scenarioId}
              />
            </div>
          </div>
        </FullWithLayout>
      </Default>
    );
  }
}

interface ConnectStateProps {
  user: User;
}

const mapStateToProps = (state) => ({
  // tslint:disable no-unsafe-any
  user: state.user.user,
});

export default connect<ConnectStateProps>(mapStateToProps)(Pay);
