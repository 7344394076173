import * as React from "react";
import Seo from "src/components/Seo";
import PayRoute from "src/routes/Pay";
import { PageProps } from "src/types";

interface Props extends PageProps {}

const pay: React.SFC<Props> = (props) => {
  return (
    <>
      <Seo pageTitle="Оплата" />
      <PayRoute {...props} />
    </>
  );
};

export default pay;
