import * as React from "react";
import Api from "src/api";
// tslint:disable-next-line: no-relative-imports
import Input from "src/components/Input";
import Typography from "src/components/Typography";
import { Coupon as CouponType } from "src/types";
// tslint:disable-next-line: no-relative-imports
import parentStyles from "../../Pay.module.css";
import styles from "./Coupon.module.css";

interface Props {
  goodId: string;
  onChange(sale: number, couponId: string): void;
}

type State = {
  error: boolean;
  coupon?: CouponType;
  value: string;
};

class Coupon extends React.Component<Props, State> {
  public state = {
    coupon: null,
    error: false,
    value: "",
  };

  public render() {
    return (
      <div className={styles.wrapper}>
        <Input
          handleChange={this.handleChange}
          value={this.state.value}
          placeholder="Промокод"
          wrapperClassName={parentStyles.leftColumn}
          error={this.state.error}
        />
        {this.renderButton()}
      </div>
    );
  }

  private checkCoupon = async () => {
    const res = await Api.checkCoupon({ code: this.state.value, goodId: this.props.goodId });
    if (!res.data) {
      this.setState({ error: true, coupon: null });
      return;
    }

    this.props.onChange(res.data.percent, res.data._id);
    this.setState({ coupon: res.data, error: false });
  };

  private handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (ev.currentTarget?.value?.length > 20) return ev.currentTarget?.value;
    this.setState({ value: ev.currentTarget.value });
    return ev.currentTarget?.value;
  };

  private renderButton = () => {
    if (this.state.coupon) {
      return (
        <div className={styles.percent}>
          <Typography type="h5">{`−${this.state.coupon.percent}%`}</Typography>
        </div>
      );
    }

    return (
      <div onClick={this.checkCoupon} role="button" className={styles.button}>
        <Typography type="boldText">ПРИМЕНИТЬ</Typography>
      </div>
    );
  };
}

export default Coupon;
